<template>
  <div id="Home">
    <!-- <Home /> -->
    <HomeTopImage />
    <EnquireyBanner />
    <template v-if="isMobile(true)">
      <img class="imagesInsert" src="https://wp-ocssport.ocs-sport.com/racepark/wp-content/uploads/sites/7/2024/11/Screenshot-2024-02-28-at-14.02.02.png">
    </template>
    <div id="about">
      <AboutTrackman />
    </div>
    <template v-if="isMobile(true)">
      <img class="imagesInsert" src="https://wp-ocssport.ocs-sport.com/racepark/wp-content/uploads/sites/7/2024/11/Screenshot-2024-02-28-at-13.16.06.png">
    </template>
    <template v-else>
      <GolfParkImages />
    </template>
    <div id="membership">
      <GolfMember />
    </div>
     <div class="AppSection">
      <b-container>
        <template v-if="isMobile(true)">
          <b-link :href="'https://apps.apple.com/gb/app/sim-park/id6738054804'" target="_blank">
            <img class="BookNowApp" src="https://wp-ocssport.ocs-sport.com/racepark/wp-content/uploads/sites/7/2024/11/BookNOw_Mobile_Banner_2-1.png">
            <b-row>
              <b-col>
              </b-col>
              <b-col>
                <b-row cols="placementmob">
                  <b-col>
                    <b-link :href="'https://apps.apple.com/gb/app/sim-park/id6738054804'" target="_blank">
                      <img class="BookNowAppAppleMob" src="https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/11/AppleAppStore.png">
                    </b-link>
                  </b-col>
                  <b-col>
                    <b-link :href="'https://play.google.com/store/apps/details?id=com.ocssoftware.simpark.app'" target="_blank">
                      <img class="BookNowAppGoogleMob" src="https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/11/GooglePlay.png">
                    </b-link>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-link>
        </template>
        <template v-else>
          <b-link :href="'https://apps.apple.com/gb/app/sim-park/id6738054804'" target="_blank">
            <img class="BookNowApp" src="https://wp-ocssport.ocs-sport.com/racepark/wp-content/uploads/sites/7/2024/11/DownloadAppBanner_1.png">
            <b-row>
              <b-col>
              </b-col>
              <b-col>
                <b-row cols="placement">
                  <b-col>
                    <b-link :href="'https://apps.apple.com/gb/app/sim-park/id6738054804'" target="_blank">
                      <img class="BookNowAppApple" src="https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/11/AppleAppStore.png">
                    </b-link>
                  </b-col>
                  <b-col>
                    <b-link :href="'https://play.google.com/store/apps/details?id=com.ocssoftware.simpark.app'" target="_blank">
                      <img class="BookNowAppGoogle" src="https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/11/GooglePlay.png">
                    </b-link>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-link>
        </template>
      </b-container>
    </div>   
    <div class="questionsSection">
       <Questions />
    </div>
    <template v-if="isMobile(true)">
      <img class="imagesInsert" src="https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/Golfpark_9.png">
      <img class="imagesInsert" src="https://wp-ocssport.ocs-sport.com/golfpark/wp-content/uploads/sites/4/2024/06/Golfpark_5.png">
    </template>
  </div>
</template>

<script>
import HomeTopImage from '@/components/HomeTopImage.vue'
import EnquireyBanner from '@/components/EnquireyBannerOne.vue'
import AboutTrackman from '@/components/AboutTrackman.vue'
import GolfParkImages from '@/components/GolfParkImages.vue'
import GolfMember from '@/components/GolfParkMember.vue'
import Questions from '@/components/QuestionandAnswers.vue'

export default {
  name: 'App',
  components: {
    HomeTopImage,
    EnquireyBanner,
    AboutTrackman,
    GolfParkImages,
    GolfMember,
    Questions
  },
  methods: {
    isMobile() {
        if( screen.width <= 768 ) {
            return true;
        }
        else {
            return false;
        }
    },
  }
}
</script>
<style scoped>
  img.BookNowAppApple {
    width: 220px;
  }
  img.BookNowAppGoogle {
    width: 220px;
  }
  .row.row-cols-placement {
    position: absolute;
    top: -9em;
    left: 8em;
  }
  .AppSection {
    padding-bottom: 100px;
  }
  ::v-deep .btn-danger {
    color: #fff;
    background-color: #7ebd63;
    border-color: #7ebd63!important;
  }
  .imagesInsert {
    width: 100%;
  }
  .BookNowApp {
    width: 100%;
  }
  @media only screen and (max-width: 1260px) {
    .row.row-cols-placement {
      position: absolute;
      top: -8em;
      left: 6em;
    } 
  }
  @media only screen and (max-width: 1230px) {
    .row.row-cols-placement {
      position: absolute;
      top: -8em;
      left: 6em;
    } 
  }
  @media only screen and (max-width: 1200px) {
    img.BookNowAppGoogle {
      width: 160px;
    }
    img.BookNowAppApple{
      width: 160px;
    }
    .row.row-cols-placement {
      position: absolute;
      top: -6.5em;
      left: 6em;
    } 
  }
  @media only screen and (max-width: 990px) {
    img.BookNowAppGoogle {
      width: 110px;
    }
    img.BookNowAppApple{
      width: 110px;
    }
    .row.row-cols-placement {
      position: absolute;
      top: -4.5em;
      left: 4em;
    } 
  }

  /* mobileAd */
  .row.row-cols-placementmob {
    position: absolute;
    top: -6.5em;
    left: -5em;
  } 
  img.BookNowAppGoogleMob {
    width: 150px;
  }
  img.BookNowAppAppleMob{
    width: 150px;
  }
  @media only screen and (max-width: 767px) {
    img.BookNowAppAppleMob {
      width: 110px;
    }
    img.BookNowAppGoogleMob{
      width: 110px;
    }
    .row.row-cols-placementmob {
      position: absolute;
      top: -4.5em;
      left: -4em;
    } 
  }
  @media only screen and (max-width: 425px) {
    img.BookNowAppAppleMob {
      width: 100px;
    }
    img.BookNowAppGoogleMob{
      width: 100px;
    }
    .row.row-cols-placementmob {
      position: absolute;
      top: -3.6em;
      left: -4em;
    } 
  }
  @media only screen and (max-width: 375px) {
    img.BookNowAppAppleMob {
      width: 80px;
    }
    img.BookNowAppGoogleMob{
      width: 80px;
    }
    .row.row-cols-placementmob {
      position: absolute;
      top: -3.1em;
      left: -3.5em;
    } 
  }
  @media only screen and (max-width: 320px) {
    img.BookNowAppAppleMob {
      width: 60px;
    }
    img.BookNowAppGoogleMob{
      width: 60px;
    }
    .row.row-cols-placementmob {
      position: absolute;
      top: -2.7em;
      left: -2.6em;
    } 
  }
</style>

